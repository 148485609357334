import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

const currentKmlSlice = createSlice({
  name: "currentKml",
  initialState,
  reducers: {
    setCurrentKml: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setCurrentKml } = currentKmlSlice.actions;

export const selectCurrentKml = (state) => state.currentKml.data;

export default currentKmlSlice.reducer;