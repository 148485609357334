import { toGeojson } from "./index.js";

export const addSymbolSource = (map, source, options, addHandler, handlerCallback) => {
    if (!source.length) {
        return;
    }
    const geoJson = toGeojson(source);
    const mapSource = map.getSource(options.source);
    if (!!mapSource) { 
        mapSource.setData(geoJson);

    } else {
        
        map.addSource(options.source, {
            type: 'geojson',
            data: geoJson,
        })
            
        map.addLayer({
            'id': options.layerId,
            'source': options.source,
            "type": "symbol",
            'layout': {
                'icon-image': options.iconImage,
                'icon-size': options.size,
                'icon-allow-overlap': options.overlap, 
            },
        });
        if (addHandler && handlerCallback) {
            const canvas = map.getCanvasContainer();
            map.on('mouseenter', options.layerId, function () {
                canvas.style.cursor = 'pointer';
            });
                 
            map.on('mouseleave', options.layerId, function () {
                canvas.style.cursor = '';
            });
            handlerCallback();
        }
    }
}