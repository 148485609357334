import { 
    cliente, 
    prospecto 
} from '../../const/images.js';

export const SelectCliente = ({setSelected}) => (
    <div className='client-container'>
        <div 
            className='select-element'
            onClick={() => setSelected("CLIENTE")}
        >
            <div className='client-image'>
                <img src={cliente} alt='cliente'/>
            </div>
            <div className='client-img-tag'>
                <p>
                    Cliente
                </p>
            </div>
        </div>
        <div 
            className='select-element'
            onClick={() => setSelected("PROSPECTO")}
        >
            <div className='client-image'>
                <img src={prospecto} alt='prospecto'/>
            </div>
            <div className='client-img-tag'>
                <p>
                    Prospecto
                </p>
            </div>
        </div>
    </div>
)