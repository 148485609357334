import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [] };

const prospectosSlice = createSlice({
  name: "prospectos",
  initialState,
  reducers: {
    setProspectos: (state, action) => {
        state.data = action.payload;
    },
    setProspecto: (state, action) => {
      const newProspecto = {
        ...action.payload, 
        type: "Point", 
        coordinates: [ JSON.parse(action.payload.lngProspecto),  JSON.parse(action.payload.latProspecto), 0]
      };
      state.data.push(newProspecto);
    },
  },
});

export const { setProspectos, setProspecto } = prospectosSlice.actions;

export const selectProspectos = (state) => state.prospectos.data;

export default prospectosSlice.reducer;