import { useSelector } from "react-redux";
import { selectAuthUser } from "../../features/authUserSlice/authUserSlice";


export const AdminObserver = ({children}) => {
    const authUser = useSelector(selectAuthUser);
    return (
        authUser.role === 'Administrador' ?
            children
        : null
    )
}
