import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

const loadingKmlSlice = createSlice({
  name: "loadingKml",
  initialState,
  reducers: {
    setloading: (state, action) => {
        state.loading = action.payload;
    },
  },
});

export const { setloading } = loadingKmlSlice.actions;

export const selectLoading = (state) => state.loadingKml.loading;

export default loadingKmlSlice.reducer;