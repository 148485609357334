import { Input } from '../Input/index.js';
import { Button } from '../Button/index.js';
import { 
    Select, 
    MenuItem, 
    IconButton,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setElement } from '../../features/elementSlice/elementSlice.js';
import './index.css';
import { handleInput } from '../../services/handle-input.js';
import { geoInputs } from '../../const/geoInputs.js';

const setInitialState = (array) => {
    const object = {};
    for (let i = 0; i < array.length; i++) {
        if (array[i].type === "select") {
            object[array[i].name] = array[i].options[0];
            continue;
        }
        object[array[i].name] = "";
        
    }
    return object;
}

export const AttributesContainer = ({map, selected, onClose, handleSelect}) => {
    const [values, setValues] = useState(setInitialState(selected.atributos));
    const [geoData, setGeoData] = useState(
        {
            longitude: 0,
            latitude: 0,
        })
    const disabled = !Object.values(values).every(value => !!value);
    
    const dispatch = useDispatch();

    const onChangeValues = (e) => {
        const { 
            target: {  
                name, 
                value 
            } 
        } = e;
        setValues(prevState => ({...prevState, [name]: value}))
    }

    const onChangeGeoDataValues = (e) => {
        const { 
            target: {  
                name, 
                value 
            } 
        } = e;
        setGeoData(prevState => ({...prevState, [name]: value}));
    }

    const handleBack = () => {
        handleSelect(null)
    }

    const handleAddElement = (e) => {
        e.stopPropagation();
        const currentelement = {
            ...selected, 
            atributos: values,
            lng: geoData.longitude,
            lat: geoData.latitude,
        }  
        map.setCenter([ geoData.longitude, geoData.latitude ])
        dispatch(setElement(currentelement));
        onClose()
    }

    return (
         <div className='attributes-container'>
            <div className='modal-content-section attributes-header'>
                <div className='back-menu-btn'>
                    <IconButton onClick={handleBack}>
                        <ArrowBack/>
                    </IconButton>
                </div>
                <div className='attributes-header-title'>
                    <h1>
                        {selected.name}
                    </h1>
                </div>
            </div>
            {selected.atributos.map((atributo) => (
                    <div className='modal-content-section' key={`key-${atributo.name}`}>
                        <div className='name-input-label'>
                            <p>
                                {atributo.name}
                            </p>
                        </div>
                        {atributo.type !== "select" ? 
                            <Input
                                onChange={onChangeValues}
                                value={values[atributo.name] || ''}
                                id={`input-${atributo.name}`}
                                name={atributo.name}
                                type={atributo.type}
                                placeholder={`Agregar ${atributo.name}`}
                            /> :
                            <Select
                                labelId="select-label"
                                id="select"
                                value={values[atributo.name] || atributo.options[0]}
                                onChange={onChangeValues}
                                name={atributo.name}
                            >
                                {atributo.options.map(option => (
                                    <MenuItem value={option} key={option}>{option}</MenuItem>
                                ))}
                            </Select>
                        }
                        
                    </div>
                ))}
            <div className="geo-inputs-container">
                {geoInputs.map((input) => (
                    handleInput(input, geoData, onChangeGeoDataValues)
                ))}
            </div>
            <div className='modal-content-section'>
                <Button onClick={handleAddElement} disabled={disabled}>
                    Seleccionar posición en el mapa
                </Button>
            </div>
        </div>
    )
}