import axios from 'axios';
import tjson from '@mapbox/togeojson';
import { ErrorPage } from '../../components/error-page/index.js';
import { Map } from '../../components/map/index.js';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { setAuthUser } from '../../features/authUserSlice/authUserSlice.js';
import { setGeocercas } from '../../features/geocercasSlice/geocercasSlice.js';
import { setUsers } from '../../features/usersSlice/usersSlice.js';
import { setKmlFiles } from '../../features/kmlSlice/kmlSlice.js';
import { setElements } from '../../features/elementsSlice/elementsSlice.js';
import { setProspectos } from '../../features/prospectosSlice/prospectosSlice.js';
import { setMarkers } from '../../features/markersSlice/markersSlice.js';
import { api } from '../../const/apiUpload.js';
import { ADMINISTRADOR } from '../../const/userRoles.js';

export const MainPage = () => {
    const [searchParams] = useSearchParams();
    const [loadingPage, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const email = searchParams.get('email');
    
    useEffect(() => {
        const fetchData = async() => {
            try {
                if (!email) {
                    setError({message: 'no hay un usuario', status: 404});
                    return;
                }
                const authUserResponse = await axios.get(`${api}/find-auth-user/${email}`);
                dispatch(setAuthUser(authUserResponse.data));
 
                const geocercasResponse = await axios.get(`${api}/find-geocercas`);
                const geocercas = geocercasResponse.data.filter((geocerca) => {
                    
                    if (authUserResponse.data.role !== ADMINISTRADOR) {
                        const includes = geocerca.associatedUsers.map(relation => relation.idUser).includes(authUserResponse.data.idUser);
                        if (includes) {
                            return true;
                        }
                        return false;
                    } else {
                        return true
                    }
                })
                
                dispatch(setGeocercas(geocercas)); 

                const prospectosResponse = await axios.get(`${api}/find-prospectos`);
                dispatch(setProspectos(prospectosResponse.data));    

                if (authUserResponse.data.role === ADMINISTRADOR) {
                    const usersResponse = await axios.get(`${api}/find-users`);
                    dispatch(setUsers(usersResponse.data));
                }
                
                const kmlResponse = await axios.get(`${api}/find-klm-files`);
                if (kmlResponse?.data.length) {
                    const requests = kmlResponse.data.map(file => `${api}/read/${file.fileName}`);
                    const all = await Promise.all(requests.map(ednpoint => axios.get(ednpoint)));
                    const nodes = all.map(({ data }) => new DOMParser().parseFromString(data, 'text/xml'));

                    //trasnforma el node object del kml en json
                    const geoJsonObjects = nodes.map(node => tjson.kml(node)).map((geoJson, index) => ({ kmlName: kmlResponse.data[index].kmlName, idkmlFiles: kmlResponse.data[index].idkmlFiles, originalName: kmlResponse.data[index].fileName, ...geoJson}))
                    dispatch(setKmlFiles(geoJsonObjects));
                }
                const query = await axios.get(`${api}/process-query`, {headers: {'x-data-query': "SELECT * FROM thomas_cobertura.tmSettingsElements where idtmMapElements='a98db973kwl8xp1lz94kjf0bma5pez8c6490fwei01qjm';"}})
                const object = JSON.parse(query.data[0].elements);
                const elementsSettingsRespose = Object.values(object);
                dispatch(setElements(elementsSettingsRespose));

                const elementsRespose = await axios.get(`${api}/find-elementos`);
                dispatch(setMarkers(elementsRespose.data));

                setLoading(false);
            } catch (error) {
                
                setError({message: error.response.data.error, status: error.response.status});
            }
        }
        fetchData();
    }, [])

    return (
        !error ? 
            <Map loadingPage={loadingPage}/>
        : <ErrorPage message={error.message} status={error.status}/>
    )
}