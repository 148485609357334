import { Button } from "../Button";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setProspectoToAdd } from "../../features/prospectoToAdd/prospectoToAddSlice.js";
import { handleInput } from "../../services";
import { geoInputs } from '../../const/geoInputs.js';

const prospectosInputs = [
    { 
        inputType: "INPUT",
        title: "Nombre",
        name: "nombreProspecto",
        id: "input-nombre",
        type: "text",
        placeholder: "Agregar nombre",
    },
    { 
        inputType: "SELECT",
        title: "Tipo de solicitud",
        name: "tipoDeSolicitud",
        id: "select",
        options: [
            {
                value: "hogar",
                name: "Hogar",
            },
            {
                value: "empresa",
                name: "Empresa",
            },
        ],
    },
    { 
        inputType: "INPUT",
        title: "Telefono",
        name: "telefono",
        id: "input-telefono",
        type: "text",
        placeholder: "Agregar telefono",
    },

    { 
        inputType: "INPUT",
        title: "Cedula",
        name: "ciProspecto",
        id: "input-cedula",
        type: "text",
        placeholder: "Agregar Cedula",
    },
    { 
        inputType: "INPUT",
        title: "correo",
        name: "correoProspecto",
        id: "input-correo",
        type: "text",
        placeholder: "Agregar correo",
    },
    { 
        inputType: "INPUT",
        title: "Ciudad",
        name: "ciudad",
        id: "input-ciudad",
        type: "text",
        placeholder: "Agregar ciudad",
    },
    { 
        inputType: "INPUT",
        title: "Zona",
        name: "zona",
        id: "input-zona",
        type: "text",
        placeholder: "Agregar zona",
    },
    { 
        inputType: "INPUT",
        title: "Dirección",
        name: "direccion",
        id: "input-direccion",
        type: "text",
        placeholder: "Agregar dirección",
    },
    
];

export const ProspectoDetails = ({
    handleBack,  
    map,
    onClose}) => {
    const dispatch = useDispatch();
    const [values, setValues] = useState({
        nombreProspecto: '',
        tipoDeSolicitud: 'hogar',
        telefono: '',
        ciProspecto: '',
        correoProspecto:'',
        ciudad: '',
        zona: '',
        direccion: '',
        longitude: 0,
        latitude: 0,
    });

    const onChangeValues = (e) => {
        const { 
            target: {  
                name, 
                value 
            } 
        } = e;
        setValues(prevState => ({...prevState, [name]: value}))
    }

    const handleAddProspecto = (e) => {
        e.stopPropagation();
      
        e.stopPropagation();
        const { 
            longitude,
            latitude,
            ciProspecto,
            ...rest
        } = values;
        const currentProspecto = {
            ciProspecto: ciProspecto.replace(/[^\d]/gi, ''),
            lngProspecto: longitude,
            latProspecto: latitude,
            ...rest, 
        }  
        map.setCenter([values.longitude, values.latitude])
        dispatch(setProspectoToAdd(currentProspecto));
        onClose();
    }
    return (
        <div className='attributes-container'>
            <div className='modal-content-section attributes-header'>
                <div className='back-menu-btn'>
                    <IconButton onClick={handleBack}>
                        <ArrowBack/>
                    </IconButton>
                </div>
                <div className='attributes-header-title'>
                    <h1>
                        Prospecto
                    </h1>
                </div>
            </div>
            {prospectosInputs.map((input) => (
                handleInput(input, values, onChangeValues)
            ))}
            <div className="geo-inputs-container">
                {geoInputs.map((input) => (
                    handleInput(input, values, onChangeValues)
                ))}
            </div>
             <div className='modal-content-section'>
                <Button onClick={handleAddProspecto}>
                    Seleccionar posición en el mapa
                </Button>
            </div>
        </div>
    )
}

  /*const { lng, lat } = map.getCenter();    
        const geojson = {
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [lng, lat]
                    }
                }
            ]
        };

        map.addSource('select-element', {
            type: 'geojson',
            data: geojson,
        })
            
        // styles para el archivo kml
        // agrega styles al los poligonos
        map.addLayer({
            'id': 'select-element',
            'source': 'select-element',
            'type': 'symbol',
            'layout': {
                'icon-image': values.tipoDeSolicitud.toLowerCase(),
                'icon-size': 0.04,
                'icon-allow-overlap': true, 
            },
        });
        const canvas = map.getCanvasContainer();
        canvas.style.cursor = 'crosshair';

        const onMove = (e) => {
            const coords = e.lngLat;
    
            // Update the Point feature in `geojson` coordinates
            // and call setData to the source layer `point` on it.
            geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
            map.getSource('select-element').setData(geojson);
        }

        const onStop = () => {
            const currentProspecto = {
                ...values, 
                lngProspecto: geojson.features[0].geometry.coordinates[0],
                latProspecto: geojson.features[0].geometry.coordinates[1],
            }            
            map.off('mousemove', onMove);
            map.removeLayer('select-element');
            map.removeSource('select-element');
            canvas.style.cursor = '';
            dispatch(setProspectoToAdd(currentProspecto));
        }

        map.on('mousemove', onMove);
        map.once('click', onStop);*/