import { selectProperties } from '../../features/featurePropertiesSlice/featurePropertiesSlice.js';
import { useSelector } from 'react-redux';
import './index.css';

export const PropertiesPanel = () => {
    const properties = useSelector(selectProperties);
    return (
        !!properties ?
            <div className='panel-container display-animation'>
                <PropertyContainer
                    properties={properties}
                    property='gx_media_links'
               >
                    <div className='property-image'>
                        <img src={properties.gx_media_links} alt='gx_media_links'/>
                    </div>
               </PropertyContainer>
               <PropertyContainer
                    properties={properties}
                    property='name'
               >
                    <div className='property-name'>
                        <h4>
                            {properties.name}
                        </h4>
                    </div>
               </PropertyContainer>
               <PropertyContainer
                    properties={properties}
                    property='description'
               >
                    <div className='property-description'>
                        <p>
                            {properties.description}
                        </p>
                    </div>
               </PropertyContainer>
            </div>
        : null
    )
}

const PropertyContainer = ({properties, property, children}) => (
    properties[property] ?
        children
    : null
)