import { 
    MenuItem, 
    Select 
} from "@mui/material";

export const SelectContainer = ({
    onChange, 
    title,
    value,
    labelId,
    id,
    name,
    options,
}) => (
    <div className='modal-content-section'>
        <div className='name-input-label'>
            <p>
                {title}
            </p>
        </div>
        <Select
            labelId={labelId}
            id={id}
            value={value}
            onChange={onChange}
            name={name}
        >
            {options.map((item) => (
                <MenuItem 
                    value={item.value}
                    key={item.value}
                >
                        {item.name}
                </MenuItem>
            ))}
        </Select>  
    </div>
)