import { 
	configureStore, 
} from "@reduxjs/toolkit";
import authUserReducer from "../features/authUserSlice/authUserSlice.js";
import kmlReducer from "../features/kmlSlice/kmlSlice.js";
import loadingKmlReducer from '../features/loadingKmlSlice/loadingKmlSlice.js';
import featurePropertiesReducer from '../features/featurePropertiesSlice/featurePropertiesSlice';
import geocercasReducer from '../features/geocercasSlice/geocercasSlice.js';
import usersReducer from '../features/usersSlice/usersSlice.js';
import prospectosReducer from "../features/prospectosSlice/prospectosSlice.js";
import geocercaReducer from '../features/geocercaSlice/geocercaSlice.js';
import userReducer from '../features/userSlice/userSlice.js';
import openModalReducer from '../features/openModalSlice/openModalSlice.js';
import currentKmlReducer from '../features/currentKmlSlice/currentKmlSlice.js';
import mapPreferenceReducer from '../features/mapPreferece/mapPreference.js';
import elementReducer from '../features/elementSlice/elementSlice.js';
import elementsReducer from "../features/elementsSlice/elementsSlice.js";
import markersReducer from '../features/markersSlice/markersSlice.js';
import clienteToAddReducer from '../features/clienteToAdd/clienteToAddSlice.js';
import clienteReducer from '../features/clienteSlice/clienteSlice.js';
import prospectoToAddReducer from '../features/prospectoToAdd/prospectoToAddSlice.js';

export default configureStore({
  reducer: {
    authUser: authUserReducer,
    user: userReducer,
    users: usersReducer,
    loadingKml: loadingKmlReducer,
    kml: kmlReducer,
    featureProperties: featurePropertiesReducer,
    geocercas: geocercasReducer,
    prospectos: prospectosReducer,
    geocerca: geocercaReducer,
    openModal: openModalReducer,
    currentKml: currentKmlReducer,
    mapPreference:  mapPreferenceReducer,
    element: elementReducer,
    elements: elementsReducer,
    markers: markersReducer,
    clienteToAdd: clienteToAddReducer,
    cliente: clienteReducer,
    prospectoToAdd: prospectoToAddReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});