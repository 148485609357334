import { Tooltip } from '@mui/material';
import * as images from '../../const/images.js';
import { v4 as uuid} from 'uuid';

import './index.css';

export const SelectElement = ({elements, handleSelect}) => (
    <div className='elements-container'>
        {elements.length ?
            elements.map((element) => (
                <Tooltip 
                    title={element.name} 
                    placement="top"  
                    key={uuid()}
                >
                    <div 
                        className='select-element'
                        onClick={() => handleSelect(element)}
                       
                    >
                        <div className='element-image'>
                            <img src={images[element.img]} alt='element'/>
                        </div>
                    </div>
               </Tooltip>
            ))
            : null}
    </div>
)