import axios from 'axios';
import { ModalBase } from '../modal-base/index.js';
import { Button } from '../Button/index.js';
import { 
    IconButton
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { 
    useDispatch 
} from 'react-redux';
import { useState } from 'react';
import { setOpen } from '../../features/openModalSlice/openModalSlice.js';
import { setCliente } from '../../features/clienteSlice/clienteSlice.js';
import { toast } from 'react-toastify';
import { handleInput } from '../../services/handle-input.js';
import { toastOptions } from '../../const/toastOptions.js';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { apiPhp } from '../../const/apiPhp.js';
import { SelectContainer } from '../select-container/index.js';


const clienteInputs = [
    { 
        inputType: "INPUT",
        title: "Cedula",
        name: "identidad",
        id: "input-identidad",
        type: "text",
        placeholder: "Agregar cedula",
    },
];

export const SearchClienteModal = () => {
    const [values, setValues] = useState({
        identidad: '',
    });
    const [data, setData] = useState(null);
    const [index, setIndex] = useState(0);
    const dispatch = useDispatch();

    const onClose = () => {
        setValues({cedula: ""});
        dispatch(setOpen(false));
    }

    const onChangeSelect = (e) => {
        const { 
            target: {  
                value 
            } 
        } = e;
        const ind = data.findIndex(item => item.abonadoid === value);
        console.log(data[ind]);
        setIndex(ind);
    }

    const onChangeValues = (e) => {
        const { 
            target: {  
                name, 
                value 
            } 
        } = e;
        
        setValues(prevState => ({...prevState, [name]: value}))
    }

    const handleSearch = async () => {
    
        if (!values.identidad) {
            return;
        }
        try {
            const response = await axios.get(`${apiPhp}ci_values/?cedula=${values.identidad.replace(/[^\d]/gi, '')}`);
            
            const {
                data: {
                    status,
                    data
                }
            } = response;
            
            if (status) {
                console.log(data)
                setData(data);
                toast.info(`La cedula pertenece a ${data[0].company}.`, toastOptions);
            } else {
                setData(null);
                toast.error(`La cedula no pertenece a un cliente Fibex.`, toastOptions);
            }
        } catch (error) {
            toast.error(`Fallo en el servidor.`, toastOptions);
        }
    }

    const handleMostrar = (cliente) => {
        if (cliente.longitude) {
            dispatch(setCliente(cliente));
        } else {
            toast.error(`El abonado ${cliente.cedula} (${cliente.abonadoid}) no tiene posición geografica.`, toastOptions);
        }
    } 

    
    const handleMostrarTodos = () => {
        if (data) {
            for (let i = 0; i < data.length; i++) {
                handleMostrar(data[i]);
            }
        }
        onClose();
    } 

    const onMostrar = () => {
        handleMostrar(data[index]);
        if (data.length === 1 && data[index].longitude) {
            onClose();
        }
    }

    return (
        <>
            <ModalBase
                id='create-user-modal'
                title='Buscar cliente'
                onClose={onClose}
            >
                <div className='modal-content'>
                    <div className='cedula-input-container'>
                        {clienteInputs.map((input) => (
                            handleInput(input, values, onChangeValues)
                        ))}
                        <div className="search-btn">
                            <IconButton onClick={handleSearch}>
                                <Search/>
                            </IconButton>
                        </div>
                    </div>
                    {data?.length > 1 ? 
                        <SelectContainer
                            title="Abonados"
                            onChange={onChangeSelect}
                            options={data.map((item) => ({name: item.abonadoid, value: item.abonadoid}))}
                            id="select-abonado"
                            labelId="select-abonado"
                            name="abonados"
                            value={data[index].abonadoid}
                        />
                        
                    : null}
                    {data ? 
                        (<>
                            <div className="name-cliente-container">
                                <h5>
                                    Cliente:
                                </h5>
                                <p>
                                    {data[index].company}
                                </p>
                            </div>
                            <div className="name-cliente-container">
                                <h5>
                                    Abonado:
                                </h5>
                                <p>
                                    {data[index].abonadoid}
                                </p>
                            </div>
                            <div className="name-cliente-container">
                                <h5>
                                    Estado:
                                </h5>
                                <p>
                                    {data[index].state}
                                </p>
                            </div>
                            <div className="name-cliente-container">
                                <h5>
                                    Ciudad:
                                </h5>
                                <p>
                                    {data[index].city}
                                </p>
                            </div>
                            <div className="name-cliente-container">
                                <h5>
                                    Dirección:
                                </h5>
                                <p>
                                    {data[index].address}
                                </p>
                            </div>
                            <div className='modal-content-section'>
                                <Button onClick={onMostrar}>
                                    Mostrar Cliente en mapa
                                </Button>
                            </div>
                            {data?.length > 1 ? 
                                <div className='modal-content-section'>
                                    <Button onClick={handleMostrarTodos}>
                                        Mostrar todos los servicios
                                    </Button>
                                </div>
                            : null }
                        </>)
                    : null}
                </div>
            </ModalBase>
            
        </>
    )
}