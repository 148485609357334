import { SidebarMenuContainer } from "../sidebar-menu-container";
import { Button } from "../Button";
import { GridContainer } from "../grid";
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';
import { selectCliente } from "../../features/clienteSlice/clienteSlice.js";
import { setOpen } from "../../features/openModalSlice/openModalSlice";
import { SEARCH_CLIENTE } from "../../const/modalName";

export const ClientesMenu = ({handleActive}) => {
    const clientes = useSelector(selectCliente);
    const dispatch = useDispatch();
    const handleSearchModal = () => {
        dispatch(setOpen(SEARCH_CLIENTE));
    }
    return (
        <SidebarMenuContainer handleActive={handleActive} title='Clientes'>
            {clientes.length ? 
                clientes.map((cliente) => (
                <GridContainer  
                    key={cliente.abonadoid}
                    name={`${cliente.company} (${cliente.abonadoid})`}
                    showMenu={false}
                >
                </GridContainer>
            ))
            :   null
            }
            <Button onClick={handleSearchModal}>
                Mostrar Cliente
            </Button>
        </SidebarMenuContainer>
    );
};