import { createSlice } from "@reduxjs/toolkit";
import { mapStyles} from "../../const/mapStyles";

const initialState = {
  style: mapStyles.mapTilerStreet,
};

const mapPreferenceSlice = createSlice({
  name: "mapPreference",
  initialState,
  reducers: {
    setStyle: (state, action) => {
        state.style = action.payload;
    },
  },
});

export const { setStyle } = mapPreferenceSlice.actions;

export const selectStyle = (state) => state.mapPreference.style;

export default mapPreferenceSlice.reducer;