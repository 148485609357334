import { SidebarMenuContainer } from "../sidebar-menu-container";
import { GridContainer } from '../grid/index.js';
import { Button } from '../Button/index.js';
import { 
    useSelector, 
    useDispatch
} from 'react-redux';
import { selectKmlInfo } from '../../features/kmlSlice/kmlSlice.js';
import { setCurrentKml } from "../../features/currentKmlSlice/currentKmlSlice";
import { setOpen } from "../../features/openModalSlice/openModalSlice";
import { DELETE_KML } from "../../const/modalName";

export const KmlMenu = ({handleActive}) => {
    const kmlItems = useSelector(selectKmlInfo);
    const handleUpload = () => {
        const target = document.getElementById('file-upload-container');
        target.classList.toggle("closed");
    }
   
    return (
        <SidebarMenuContainer handleActive={handleActive} title="Seleccionar Kml">
            {!!kmlItems.length ?
                kmlItems.map((kml) => (
                    <GridContainer  
                        key={kml.idkmlFiles}
                        name={kml.kmlName}
                    
                    >
                        <GridMenu kml={kml}/>
                    </GridContainer>
                ))
            :   <div className='modal-content-section'>
                    <p>
                        No hay kml cargados
                    </p>   
                </div>}
            <Button onClick={handleUpload}>
                Cargar archivo kml
            </Button>
        </SidebarMenuContainer>
    );
};


const GridMenu = ({kml}) =>  {
    const dispatch = useDispatch();
    const handleDelete = () => {
        dispatch(setCurrentKml(kml));
        dispatch(setOpen(DELETE_KML));
    };
    return (
        <div className='grid-menu'>
            <ul>
                <li onClick={handleDelete}>Eliminar</li>
                <li>Sobreescribir</li>
            </ul>
        </div>
    )
}