import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

const elementSlice = createSlice({
  name: "element",
  initialState,
  reducers: {
    setElement: (state, action) => {
        state.data = action.payload;
    },
  },
});

export const { setElement } = elementSlice.actions;

export const selectElement = (state) => state.element.data;

export default elementSlice.reducer;