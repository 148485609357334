import { SidebarToggle } from '../sidebar-toggle/index.js';
import { SidebarCard } from '../sidebar-card/index.js';
import { GeocercasMenu } from '../geocercas-menu/index.js';
import { UsersMenu } from '../users-menu/index.js';
import { KmlMenu } from '../kmlMenu/index.js';
import { ClientesMenu } from '../clientes-menu/index.js';
import { ProspectosMenu } from '../prospectos-menu/index.js';
import { 
    IconButton,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { cards } from '../../const/sidebarCards.js';
import * as MENU from '../../const/sidebarMenus.js';
import './index.css';

export const Sidebar = () => {
    const [toggle, setToggle] = useState(false);
    const [active, setActive] = useState(false);

    const onToggle = () => {
        setToggle(prevState => !prevState);
    };

    const handleMenu = () => {
        switch (active) {
            case MENU.USUARIOS: return (<UsersMenu handleActive={setActive}/>)
            case MENU.GEOCERCAS: return (<GeocercasMenu handleActive={setActive}/>)
            case MENU.KML: return (<KmlMenu handleActive={setActive}/>)
            case MENU.CLIENTES: return (<ClientesMenu handleActive={setActive}/>)
            case MENU.PROSPECTOS: return (<ProspectosMenu handleActive={setActive}/>)
            default:
                return null;
        }
    }
    return (
        <>
        <SidebarToggle onClick={onToggle}/>
        <div className={`sidebar-container ${toggle ? 'sidebar-open' : ''}`}>
            <div className='sidebar-search-container'>
                <div className='input-search-container'>
                    <IconButton onClick={onToggle}>
                        <ArrowBack/>
                    </IconButton>
                    <div className='sidebar-title-container'>
                        <p>
                            Menu
                        </p>
                    </div>
                </div>
            </div>  
            <div className='sidebar-content-container'>
                <div className='sidebar-content-overflow'>
                    <div className='sidebar-content'>
                        {cards.map(({title, Icon}) => (
                            <SidebarCard 
                                title={title} 
                                Icon={Icon}
                                key={uuid()}   
                                handleActive={setActive} 
                            />
                        ))}    
                    </div>
                </div>
                {handleMenu()}
            </div>
        </div>
        </>
    )
}