import axios from 'axios';
import { DeleteModal } from '../delete-modal/index.js';
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';
import { useState } from 'react';
import { 
    setCurrentKml,
    selectCurrentKml,
} from '../../features/currentKmlSlice/currentKmlSlice.js';
import { deleteKml } from '../../features/kmlSlice/kmlSlice.js';
import { setOpen } from '../../features/openModalSlice/openModalSlice.js';
import { toast } from 'react-toastify';
import { toastOptions } from '../../const/toastOptions.js';
import { api } from '../../const/apiUpload.js';

export const DeleteKml = () => {
    const kml = useSelector(selectCurrentKml);
    const dispatch = useDispatch();
    const [processing, setProcessing] = useState(false);

    const onClose = () => {
        setProcessing(false);
        dispatch(setCurrentKml(null));
        dispatch(setOpen(false));
    }
    if (!kml) {
        return null;
    }

    const handleSubmit = async() => {
        if (processing) {
            return;
        }
        try {
            setProcessing(true);
            await axios.delete(`${api}/delete-kml/${kml.idkmlFiles}/${kml.originalName}`);
            dispatch(deleteKml(kml.idkmlFiles));
            toast.info(`El archivo ${kml.kmlName} ha sido eliminado`, toastOptions);
        } catch (error) {
            toast.error(`${error.response.data.error}`, toastOptions);
        }
        onClose();
    }
    
    return (
        <DeleteModal
            onClose={onClose}
            onSubmit={handleSubmit}
            title='Eliminar archivo Kml'
            message={`¿Desea eliminar el archivo ${kml.kmlName}?`}
        />
    )
}