import axios from 'axios';
import { ModalBase } from '../modal-base/index.js';
import { Button } from '../Button/index.js';
import { useState } from 'react';
import { 
  useDispatch, 
  useSelector 
} from 'react-redux';
import { 
    selectClienteToAdd,
    setClienteToAdd
} from '../../features/clienteToAdd/clienteToAddSlice.js';
import { toast } from 'react-toastify';
import * as images from '../../const/images.js';
import { toastOptions } from '../../const/toastOptions.js';
import './index.css';
import { apiPhp } from '../../const/apiPhp.js';
import { setCliente } from '../../features/clienteSlice/clienteSlice.js';

export const SaveCliente = () => {
    const dispatch = useDispatch();
    const cliente = useSelector(selectClienteToAdd);
    const [processing, setProcessing] = useState(false);
    if (!cliente) {
      return null;
    };
  
    const onClose = () => {
      setProcessing(false);
      dispatch(setClienteToAdd(null));
    };
  
    const handleSubmit = async () => {
      if (processing) {
        return;
      }
      try {
        setProcessing(true);
        const response = await axios.get(`${apiPhp}uc/?userid=${cliente.userid}&longitude=${cliente.longitude}&latitude=${cliente.latitude}`);
        const { data } = response;
        console.log(data)
        console.log(cliente)
        dispatch(setCliente({...cliente, latitude: data.latitude, longitude: data.longitude}));
        toast.success(`El cliente ${data.company}, ha sido actualizada su posición en el mapa.`, toastOptions);
      } catch (error) {
        toast.error(`No se ha podido actulizar la posición en el mapa de ${cliente.company}.`, toastOptions);
      }
      onClose();
    };
  
    return (
      <ModalBase
        id='Save-element-modal'
        title='Actualizar posición geografica'
        onClose={onClose}
      >
        <div className='modal-content-section'>
          <div className='element-name-container'>
            <p>
              {cliente.company}
            </p>
          </div>
        </div>
        <div className='modal-content-section'>
          <div  className='element-image'>
            <img src={images.person} alt="cliente"/>
          </div>  
        </div>
        <div className='modal-content-section'>
            <div className='element-properties-container' >
              <p className='element-propertie'>Nombre: {cliente.company}</p>
            </div>
            <div className='element-properties-container' >
              <p className='element-propertie'>Abonado: {cliente.abonadoid}</p>
            </div>
            <div className='element-properties-container' >
              <p className='element-propertie'>Cedula: {cliente.cedula}</p>
            </div>
            <div className='element-properties-container' >
              <p className='element-propertie'>Estado: {cliente.state}</p>
            </div>
            <div className='element-properties-container' >
              <p className='element-propertie'>Ciudad: {cliente.city}</p>
            </div>
            <div className='element-properties-container' >
              <p className='element-propertie'>Dirección: {cliente.address}</p>
            </div>
        </div>
        <div className='modal-content-section'>
          <Button onClick={handleSubmit} processing={processing} disabled={processing}>
            Actualizar Posición
          </Button>
        </div>
      </ModalBase> 
    )
}