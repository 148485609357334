import { 
    AccountCircle,
    Map,
    Terrain,
    Group,
    GroupAdd,
} from '@mui/icons-material';

export const cards = [
    { title: 'Usuarios', Icon: AccountCircle },
    { title: 'Geocercas', Icon: Map },
    { title: 'Kml', Icon: Terrain },
    { title: 'Clientes', Icon: Group },
    { title: 'Prospectos', Icon: GroupAdd },
]