import { Input } from "../Input";


export const InputContainer = ({
    onChange, 
    title,
    value,
    id,
    name,
    type,
    placeholder
}) => (

    <div className='modal-content-section'>
        <div className='name-input-label'>
            <p>
                {title}
            </p>
        </div>
        <Input
            onChange={onChange}
            value={value}
            id={id}
            name={name}
            type={type}
            placeholder={placeholder}
        />   
    </div>
)