export const geoInputs = [
    { 
        inputType: "INPUT",
        title: "Longitude",
        name: "longitude",
        id: "input-longitude",
        type: "number",
    },
    { 
        inputType: "INPUT",
        title: "Latitude",
        name: "latitude",
        id: "input-latitude",
        type: "number",
    }
];