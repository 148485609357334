import { useEffect } from "react";
import { 
    useSelector, 
} from 'react-redux';
import { useVerifyRole } from "../../hooks/index.js";
import { Popup } from 'maplibre-gl';
import { selectCliente } from '../../features/clienteSlice/clienteSlice.js';
import { selectStyle } from '../../features/mapPreferece/mapPreference.js';
import { addSymbolSource } from "../../services/index.js";
import { ADMINISTRADOR } from "../../const/userRoles.js";
import * as images from "../../const/images";
import './index.css';

const createPopup = (map, properties, coordinates, admin) => {
    const atributosHtml =
        `<div class='markers-popup-atribute-container'>
            <p class='markers-popup-atribute'>Abonado: ${properties.abonadoid}</p> 
        </div>
        <div class='markers-popup-atribute-container'>
            <p class='markers-popup-atribute'>Cedula: ${properties.cedula}</p> 
        </div>
        <div class='markers-popup-atribute-container'>
            <p class='markers-popup-atribute'>Telefono: ${properties.phonenumber}</p> 
        </div>
        <div class='markers-popup-atribute-container'>
            <p class='markers-popup-atribute'>Estado: ${properties.state}</p> 
        </div>
        <div class='markers-popup-atribute-container'>
            <p class='markers-popup-atribute'>Ciudad: ${properties.city}</p> 
        </div>
        <div class='markers-popup-atribute-container'>
            <p class='markers-popup-atribute'>Dirección: ${properties.address}</p> 
        </div>
        `;
    

    const popupHtml =  `
        <div class='markers-popup-container'>
            <div class='markers-popup-tittle-container'>
                <h2 class='markers-popup-tittle'>${properties.company}</h2>
            </div>        
            <div class='markers-popup-atributes-container'>               
                ${atributosHtml}
            </div>
            
        </div>
    `
    /*${admin ? "<button class='button-base button-popup'>Editar</button>": ''} */
    new Popup({ offset: 20 })
        .setLngLat(coordinates)
        .setHTML(popupHtml)
        .addTo(map);
}


export const ClientesMap = ({map}) => {
    const cliente = useSelector(selectCliente);
    const style = useSelector(selectStyle);
    const administrador = useVerifyRole(ADMINISTRADOR);
  
    const options = { 
        source: 'clientes',
        layerId: 'clientes',
        iconImage: "cliente-mapa",
        size: 0.04,
        overlap: false,
    };

    const handlerCallback = () => {
        map.on("click", "clientes", (e) => {
            const [target] = map.queryRenderedFeatures(e.point, {layers: ["clientes"]})
            const { properties } = target;
            const coordinates = JSON.parse(properties.coordinates);
            console.log(properties)
            createPopup(map, properties, coordinates, administrador);
        })
    }

    useEffect(() => {
        if (cliente) {
            const last = cliente[cliente.length - 1];
            addSymbolSource(map, cliente, options, true, () => {handlerCallback()});
            if (last) {
                map.setCenter(last.coordinates)
            }
        }
        
    }, [cliente]);

    useEffect(() => {
        map.once(('style.load'), function () {
            addSymbolSource(map, cliente, options);
        })
    }, [style]);
    
    return null
}