import * as modals from '../../const/modalName';
import { AssosiateUsersModal } from '../assosiate-users-modal/index.js';
import { AssosiateGeocercasModal } from '../assosiate-geocercas-modal/index.js';
import { CreateUserModal } from '../create-user-modal';
import { UpdateUserModal } from '../update-user-modal';
import { DeleteGeocerca } from '../delete-geocerca/index.js';
import { DeleteUser } from '../delete-user/index.js';
import { DeleteKml } from '../delete-kml';
import { selectOpen } from '../../features/openModalSlice/openModalSlice';
import { useSelector } from 'react-redux';
import { SearchClienteModal } from '../search-cliente';

export const Modals = () => {
    const modal = useSelector(selectOpen);
    const handleOpen = (modalName) => {
        switch (modalName) {
            case modals.ASSOSIATE_USER_TO_GEOCERCA: return (<AssosiateUsersModal/>)
            case modals.ASSOSIATE_GEOCERCA_TO_USER: return (<AssosiateGeocercasModal/>)
            case modals.CREATE_USER: return <CreateUserModal/>;
            case modals.UPDATE_USER: return (<UpdateUserModal/>);
            case modals.DELETE_GEOCERCA: return (<DeleteGeocerca/>);
            case modals.DELETE_USER: return (<DeleteUser/>);
            case modals.DELETE_KML: return (<DeleteKml/>);
            case modals.SEARCH_CLIENTE: return (<SearchClienteModal/>);
            default:
                return null;
        }
    }
    return handleOpen(modal)
}