import { IconButton } from '@mui/material';
import { 
    Error,
    Close,
} from '@mui/icons-material';
import "./index.css";

export const ErrorUpload = ({message="algo salio mal", onClick}) => (
    <div className="error-upload-container">
        <div className="error-icon-container">
            <Error/>
        </div>
        <div className="error-message-container">
            <div className="error-title">
                <h5 className="">
                    Error
                </h5>
            </div>
            <div className="error-message">
                <p className="">
                    {message}
                </p>
            </div>
        </div>
        <div 
            className="close-error"
        >
            <IconButton onClick={onClick}>
                <Close/>
            </IconButton>
        </div>
    </div>
)