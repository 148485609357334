import { InputContainer } from "../components/input-container";
import { SelectContainer } from "../components/select-container";

export const handleInput = (input, values, onChange) => {
    switch (input.inputType) {
        case "INPUT":
            return (
                <InputContainer
                    key={input.id}
                    onChange={onChange}
                    title={input.title}
                    value={values[input.name]}
                    id={input.id}
                    name={input.name}
                    type={input.type}
                    placeholder={input.placeholder}
                />
            )
        case "SELECT":
            return (
                <SelectContainer
                    key={input.id}
                    onChange={onChange}
                    title={input.title}
                    value={values[input.name]}
                    labelId={`select-${input.id}`}
                    id={input.id}
                    name={input.name}
                    options={input.options}
                />
            )
        default:
            return null;
    }
}