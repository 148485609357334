import './index.css';

export const SidebarCard = ({title, Icon, handleActive}) => {
    return (
        <div 
            className='sidebar-card-container'
            onClick={() => handleActive(title.toUpperCase())}    
        >
            <div className='sidebar-card'>
                <div className='sidebar-card-icon'>
                    <Icon/>
                </div>
                <div className='sidebar-card-title'>
                    <p>
                        {title}
                    </p>
                </div>
            </div>
        </div>
    )
};
