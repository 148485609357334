import * as images from './images.js';

export const mapImages = [
    { 
        img: images.cajaNap, 
        name: 'caja-nap' 
    }, 
    { 
        img: images.manga, 
        name: 'manga' 
    },
    { 
        img: images.cliente, 
        name: 'cliente' 
    }, 
    { 
        img: images.person, 
        name: 'cliente-mapa' 
    },
    { 
        img: images.prospecto, 
        name: 'prospecto' 
    },  
    { 
        img: images.empresa, 
        name: 'empresa' 
    }, 
    { 
        img: images.hogar, 
        name: "hogar" 
    },
    {
        img: images.condominio,
        name: "condominio"
    },
    {
        img: images.poste, 
        name: "poste" 
    },
    {
        img: images.caja,
        name: "caja"
    },
    {
        img: images.hub,
        name: "hub",
    }

]