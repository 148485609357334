import { ProspectoDetails } from '../prospecto-details/index.js';
import { ClienteDetails } from '../cliente-details/index.js';
import { SelectCliente } from '../select-cliente/index.js';
import { ModalBase } from '../modal-base/index.js';
import { useState } from 'react';
import './index.css';

export const AddCliente = ({map}) => {
    const [selected, setSelected] = useState("DEFAULT");
    const onClose = () => {
        const target = document.getElementById('add-clients-container');
        target.classList.toggle("closed");
        handleBack();
    };

    const handleBack = () => {
        setSelected("DEAFAULT");
    };

    const handleSelect = (selected) => {
        switch (selected) {
            case "PROSPECTO":
                return (
                    <ProspectoDetails 
                        handleBack={handleBack}
                        map={map}
                        onClose={onClose}
                    />
                )
            case "CLIENTE":
                return (
                    <ClienteDetails 
                        handleBack={handleBack}
                        map={map}
                        onClose={onClose}
                    />
                )
            default:
                return (
                    <SelectCliente setSelected={setSelected}/>
                )
        }
    };

    return (
        <ModalBase 
            id='add-clients-container'
            title='Seleccionar'
            onClose={onClose}
            extend='closed'
        >
            {handleSelect(selected)}
        </ModalBase>
    )
}