import axios from 'axios';
import { ModalBase } from '../modal-base/index.js';
import { Button } from '../Button/index.js';
import { Input } from '../Input/index.js';
import { draw  } from '../../controls/draw/index.js';
import { api } from '../../const/apiUpload.js';
import { 
    useSelector, 
    useDispatch
} from 'react-redux';
import { 
    selectGeocercas, 
    setGeocercas,
    setGeocerca
} from '../../features/geocercasSlice/geocercasSlice.js';
import { selectStyle } from '../../features/mapPreferece/mapPreference.js';
import { 
    useEffect, 
    useState 
} from 'react';
import { useVerifyRole } from '../../hooks/useVerifyRole.js';
import { toast } from 'react-toastify';
import { toGeojson } from '../../services/index.js';
import { toastOptions } from '../../const/toastOptions.js';
import { ADMINISTRADOR } from '../../const/userRoles.js';
import './index.css';

export const GeocercasMap = ({ map }) => {
    const [currentGeocercas, setCurrent] = useState(null);
    const [display, setDisplay] = useState(false);
    const [value, setValue] = useState("");
    const [processing, setProcessing] = useState(false);
    const geocercas = useSelector(selectGeocercas);
    const style = useSelector(selectStyle);
    const administrador = useVerifyRole(ADMINISTRADOR);
    const dispatch = useDispatch();

    const onChangeName = (e) => {
        setValue(e.target.value);
    }

    const addGeocercasSources = () => {
        
        const geoJson = toGeojson(geocercas);
        const source = map.getSource('geocercas');
        if (!!source) { 
            if (!geocercas.length) {
                map.removeLayer('geocercas-fill');
                map.removeLayer('geocercas-line');
                map.removeSource('geocercas');
                return;
            }
            source.setData(geoJson)
        } else {
            if (!geocercas.length) {
                return;
            }
            map.addSource('geocercas', {
                type: 'geojson',
                data: geoJson,
            })
            
            map.addLayer({
                source: 'geocercas',
                id: 'geocercas-fill',
                type: 'fill',
                filter: [
                  'all',
                  ['==', '$type', 'Polygon'],
                ],
                paint: {
                  'fill-color': '#3bb2d0',
                  'fill-outline-color': '#3bb2d0',
                  'fill-opacity': 0.1,
                },
              });
              map.addLayer({
                source: 'geocercas',
                id: 'geocercas-line',
                type: 'line',
                paint: {
                  'line-color': '#3bb2d0',
                  'line-width': 2,
                },
            });
        }
    }

    useEffect(() => {
        if (!map) { return }
        if (administrador) {
            map.addControl(draw);
            map.on('draw.create', (e) => {
                const [geocercas] = e.features;
                setCurrent(geocercas);
                setDisplay(true)
            }); 
        }
    }, [])

    useEffect(() => {
        addGeocercasSources();
    }, [geocercas])
    
    useEffect(()=> {
        map.once(('style.load'), function () {
            addGeocercasSources();
        })
    }, [style])

    const onClose = () => {
        draw.deleteAll();
        setProcessing(false);
        setDisplay(false);
        setCurrent(null);
        setValue('');
    }

    const handleSubmit = async () => {
        if (!currentGeocercas || !value) {
            return;
        }
        try {
            setProcessing(true)
            const dataObject = {...currentGeocercas, name: value};
            const response = await axios.post(`${api}/create-geocercas`, dataObject);
            const { data } = response;
            dispatch(setGeocerca(data));
            toast.success(`La geocerca ${data.name} ha sido creada`, toastOptions);
            onClose();
        } catch (error) {
            toast.error(`${error.response.data.error}`, toastOptions);
        }
    }

    return (
        display ?
                <ModalBase
                    id="geocercas-modal-container"
                    title='Geocercas'
                    onClose={onClose}
                >
                    <div className='modal-content-section'>
                        <div className='name-input-label'>
                            <p>
                                Nombre
                            </p>
                        </div>
                        <Input
                            id="add-geocerca-input"
                            value={value}
                            onChange={onChangeName}
                            placeholder='Agrega un nombre a la geocerca'
                        />
                    </div>
                    <div className='modal-content-section'>
                        <Button onClick={handleSubmit} processing={processing}>
                            Guardar
                        </Button>
                    </div>
                </ModalBase>
        : null
    )
};
