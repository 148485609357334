import { SidebarMenuContainer } from "../sidebar-menu-container";
import { GridContainer } from "../grid";
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';
import { selectProspectos } from '../../features/prospectosSlice/prospectosSlice.js';

export const ProspectosMenu = ({handleActive}) => {
    const prospectos = useSelector(selectProspectos);
    const dispatch = useDispatch();
    


    return (
        <SidebarMenuContainer handleActive={handleActive} title='Prospectos'>
            {!!prospectos.length ? 
                prospectos.map((prospecto) => (
                <GridContainer  
                    key={prospecto.idProspectos}
                    name={prospecto.nombreProspecto}
                    showMenu={false}
                >
                </GridContainer>
            ))
            :   <div className='modal-content-section'>
                    <p>
                        No hay ningun prospecto registrado
                    </p>
                </div>
            }
          
        </SidebarMenuContainer>
    );
};