import maplibregl from 'maplibre-gl';
import { Sidebar } from '../sidebar/index.js';
import { PropertiesPanel } from '../properties-panel/index.js';
import { KmlMap } from '../kml-map/index';
import { ProspectosMap } from '../prospectos-map/index.js';
import { GeocercasMap  } from '../geocercas-map/index.js';
import { MarkersMap } from '../markers-map/index.js';
import { FileUpload } from '../file-upload/index.js';
import { AddElement } from '../add-element/index.js';
import { AddCliente } from '../add-cliente/index.js';
import { SaveElement } from '../save-element/index.js';
import { MapStyles } from '../map-styles/index.js';
import { Controls } from '../controls/index.js';
import { AdminObserver } from '../admin-observer/index.js';
//import { CustomDrawModal } from '../custom-draw/index.js';
import { SaveCliente } from '../save-cliente/index';
import { ClientesMap } from '../clientes-map/index.js';
import { SaveProspecto } from '../save-prospecto/index.js';
import { Modals } from '../Modals';
import { LoadingPage } from '../loading-page/index.js';
import { LoadingSnipper } from '../loading-snipper/index.js';
import { ToastContainer } from 'react-toastify';
import { 
    useRef, 
    useEffect, 
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { selectLoading } from '../../features/loadingKmlSlice/loadingKmlSlice.js';
import { setProperties } from '../../features/featurePropertiesSlice/featurePropertiesSlice.js';
import { selectStyle } from '../../features/mapPreferece/mapPreference.js';
//import { circle } from '@turf/turf';
import { mapImages } from '../../const/mapImage.js';
import 'maplibre-gl/dist/maplibre-gl.css';
import './index.css';

export const Map = ({loadingPage}) => {
    
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [loadingMap, setLoading] = useState(true);
    const loadingKml = useSelector(selectLoading);
    const style = useSelector(selectStyle);
    const dispatch = useDispatch();

    // esto debe de cargar de una Base de Datos o la ultima posicion del usuario
    const [lng] = useState(-66.902);
    const [lat] = useState(10.491);
    const [zoom] = useState(12);
    // Puedo tener un API por Usuario en caso de que sea necesario.    

    //const customDraw = new CustomDrawControl;

    useEffect(() => {
        if (map.current) { return;}
        // crea una intance del mapa
        
        map.current = new maplibregl.Map({
            container: mapContainer.current,
            center: [lng, lat],
            style: style.url,
            zoom: zoom,
            
        });
        map.current.on('style.load', () => { 
            //agrega caja nap como imagen al mapa para uusar en el layer
            mapImages.forEach((item) => {
                map.current.loadImage(item.img, (error, image)  => {
                    if (error) throw error;
                    map.current.addImage(item.name, image)
                })
            })
            
            //map.current.addControl(customDraw);
            setLoading(false);
        })
        //hacer click en una parte no dibujada del mapa cierra el panel de propiedades y deselecciona cualquier feature (poligono, punto, linea)
        map.current.on('click', (e) => {
            const outSideLayer = map.current.queryRenderedFeatures(e.point).filter(item => item.source !== 'openmaptiles');
            const emptyLayer = !outSideLayer.length;

            const selectedFeautre = map.current.getSource('selectedFeature');
            if(emptyLayer  && !!selectedFeautre) {
                map.current.removeLayer('selectedFeature')
                map.current.removeSource('selectedFeature');
                dispatch(setProperties(null));
            }
        })
    });
    return (
        <div className='map-wrap'>
            <div ref={mapContainer} className="map">
            </div>
            {(!loadingMap && !loadingPage) ? (
                <>  
                    <Controls map={map.current}/>
                    <AdminObserver>
                        <Sidebar/>
                    </AdminObserver>
                    <KmlMap map={map.current}/>
                    <GeocercasMap map={map.current}/> 
                    <ProspectosMap map={map.current}/>
                    <MarkersMap map={map.current}/>
                    <ClientesMap map={map.current}/>
                    <MapStyles map={map.current}/>
                    <PropertiesPanel/>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <AdminObserver>
                        <FileUpload/>
                    </AdminObserver>
                    <AdminObserver>
                        <AddElement map={map.current}/>
                    </AdminObserver>
                    <AdminObserver>
                        <AddCliente map={map.current}/>
                    </AdminObserver>
                    <AdminObserver>
                        <SaveElement/>
                    </AdminObserver>
                    <AdminObserver>
                        <SaveCliente/>
                    </AdminObserver>
                    <AdminObserver>
                        <SaveProspecto/>
                    </AdminObserver>
                    <AdminObserver>
                        <Modals/>
                    </AdminObserver>
                </>
            ) : <LoadingPage/>}
            {loadingKml ? <LoadingSnipper/> : null}
        </div>
       
      );
}