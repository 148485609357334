import { Popup } from 'maplibre-gl';
import { useEffect } from "react";
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';
import { selectProspectos } from '../../features/prospectosSlice/prospectosSlice.js';
import { selectStyle } from '../../features/mapPreferece/mapPreference.js';
import { addSymbolSource} from "../../services/index.js";
import { useVerifyRole } from "../../hooks/useVerifyRole.js";
import { ADMINISTRADOR } from "../../const/userRoles.js";

const createPopup = (map, properties, coordinates, admin) => {
    const atributosHtml =
        `
        <div class='markers-popup-atribute-container'>
            <p class='markers-popup-atribute'>Cedula: ${properties.ciProspecto}</p> 
        </div>
        <div class='markers-popup-atribute-container'>
        <p class='markers-popup-atribute'>Tipo de solicitud: ${properties.tipoDeSolicitud}</p> 
        </div>
        <div class='markers-popup-atribute-container'>
            <p class='markers-popup-atribute'>Correo: ${properties.correoProspecto}</p> 
        </div>
        <div class='markers-popup-atribute-container'>
            <p class='markers-popup-atribute'>Telefono: ${properties.telefono}</p> 
        </div>
        
        <div class='markers-popup-atribute-container'>
            <p class='markers-popup-atribute'>Ciudad: ${properties.ciudad}</p> 
        </div>
        <div class='markers-popup-atribute-container'>
            <p class='markers-popup-atribute'>Zona: ${properties.zona}</p> 
        </div>
        <div class='markers-popup-atribute-container'>
            <p class='markers-popup-atribute'>Dirección: ${properties.direccion}</p> 
        </div>
        `;
    

    const popupHtml =  `
        <div class='markers-popup-container'>
            <div class='markers-popup-tittle-container'>
                <h2 class='markers-popup-tittle'>${properties.nombreProspecto}</h2>
            </div>        
            <div class='markers-popup-atributes-container'>               
                ${atributosHtml}
            </div>
            
        </div>
    `
    /*${admin ? "<button class='button-base button-popup'>Editar</button>": ''} */
    new Popup({ offset: 20 })
        .setLngLat(coordinates)
        .setHTML(popupHtml)
        .addTo(map);
}


export const ProspectosMap = ({map}) => {
    const prospectos = useSelector(selectProspectos);
    const style = useSelector(selectStyle);
    const administrador = useVerifyRole(ADMINISTRADOR);

    const options = { 
        source: 'prospectos',
        layerId: 'prospectos',
        iconImage: ["get", "tipoDeSolicitud"],
        size: 0.04,
        overlap: false,
    };

    const handlerCallback = () => {
        map.on("click", "prospectos", (e) => {
            const [target] = map.queryRenderedFeatures(e.point, {layers: ["prospectos"]})
            

            const { properties } = target;
            const coordinates = JSON.parse(properties.coordinates);
            console.log(properties)
            createPopup(map, properties, coordinates, administrador);
        })
    };

    useEffect(() => {
        addSymbolSource(map, prospectos, options, true, () => {handlerCallback()});
    }, [prospectos]);

    useEffect(()=> {
        map.once(('style.load'), function () {
            addSymbolSource(map, prospectos, options);
        })
    }, [style])
    return null
}