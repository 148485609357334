import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  properties: null
};

const featurePropertiesSlice = createSlice({
  name: "featureProperties",
  initialState,
  reducers: {
    setProperties: (state, action) => {
        state.properties = action.payload;
    },
  },
});

export const { setProperties } = featurePropertiesSlice.actions;

export const selectProperties = (state) => state.featureProperties.properties;

export default featurePropertiesSlice.reducer;