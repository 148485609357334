import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

const clienteToAddSlice = createSlice({
  name: "clienteToAdd",
  initialState,
  reducers: {
    setClienteToAdd: (state, action) => {
        state.data = action.payload;
    },
  },
});

export const { setClienteToAdd } =   clienteToAddSlice.actions;

export const selectClienteToAdd = (state) => state.clienteToAdd.data;

export default  clienteToAddSlice.reducer;