import axios from 'axios';
import { DeleteModal } from '../delete-modal/index.js';
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';
import { 
    setGeocerca,
    selectGeocerca,
} from '../../features/geocercaSlice/geocercaSlice.js';
import { deleteGeocerca } from '../../features/geocercasSlice/geocercasSlice.js';
import { toast } from 'react-toastify';
import { toastOptions } from '../../const/toastOptions.js';
import { api } from '../../const/apiUpload.js';

export const DeleteGeocerca = () => {
    const geocerca = useSelector(selectGeocerca);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(setGeocerca(null));
    }
    if (!geocerca) {
        return null;
    }

    const handleSubmit = async() => {
        try {
            await axios.delete(`${api}/delete-geocerca/${geocerca.idGeocerca}`);
            dispatch(deleteGeocerca(geocerca.idGeocerca));
            toast.info(`La geocerca ${geocerca.name} ha sido eliminado`, toastOptions);
        } catch (error) {
            toast.error(`${error.response.data.error}`, toastOptions);
        }
        onClose();
    }
    return (
        <DeleteModal
            onClose={onClose}
            onSubmit={handleSubmit}
            title='Eliminar Geocerca'
            message={`¿Desea eliminar la geocerca ${geocerca.name}?`}
        />
    )
}