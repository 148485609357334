import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const clienteSlice = createSlice({
  name: "cliente",
  initialState,
  reducers: {
    setCliente: (state, action) => {
      const currentAbonados = state.data.map((item) => item.abonadoid);
      const newCliente = {
        ...action.payload, 
        type: "Point", 
        coordinates: [ JSON.parse(action.payload.longitude),  JSON.parse(action.payload.latitude), 0]
      };
      if (currentAbonados.includes(action.payload.abonadoid)) {
        const index = currentAbonados.indexOf(action.payload.abonadoid);
        state.data.splice(index, 1, newCliente);
      } else {
        state.data.push(newCliente);
      }
        
    },
  },
});

export const { setCliente } =   clienteSlice.actions;

export const selectCliente = (state) => state.cliente.data;

export default  clienteSlice.reducer;