import { useEffect } from "react";
import { 
    useSelector, 
} from 'react-redux';
import { useVerifyRole } from "../../hooks/index.js";
import { Popup } from 'maplibre-gl';
import { selectMarkers } from '../../features/markersSlice/markersSlice.js';
import { selectStyle } from '../../features/mapPreferece/mapPreference.js';
import { addSymbolSource } from "../../services/index.js";
import { ADMINISTRADOR } from "../../const/userRoles.js";
import './index.css';

const createPopup = (map, atributos, properties, coordinates, admin) => {
    const atributosHtml = Object.keys(atributos).map(property =>
        `<div class='markers-popup-atribute-container'>
            <p class='markers-popup-atribute'>${property}: ${atributos[property]}</p> 
        </div>`
    ).join("");

    const popupHtml =  `
        <div class='markers-popup-container'>
            <div class='markers-popup-tittle-container'>
                <h2 class='markers-popup-tittle'>${properties.name}</h2>
            </div>        
            <div class='markers-popup-atributes-container'>               
                ${atributosHtml}
            </div>
            ${admin ? "<button class='button-base button-popup'>Editar</button>": ''}
        </div>
    `
    new Popup({ offset: 20 })
        .setLngLat(coordinates)
        .setHTML(popupHtml)
        .addTo(map);
}


export const MarkersMap = ({map}) => {
    const markers = useSelector(selectMarkers);
    const style = useSelector(selectStyle);
    const administrador = useVerifyRole(ADMINISTRADOR);
  
    const options = { 
        source: 'markers',
        layerId: 'markers',
        iconImage: ["get", "mapImg"],
        size: ["get", "size"],
        overlap: false,
    };

    const handlerCallback = () => {
        map.on("click", "markers", (e) => {
            const [target] = map.queryRenderedFeatures(e.point, {layers: ["markers"]})
            const { properties } = target;
            const coordinates = JSON.parse(properties.coordinates);
            const atributos = JSON.parse(properties.atributos);
            createPopup(map, atributos, properties, coordinates, administrador);
        })
    }

    useEffect(() => {
        addSymbolSource(map, markers, options, true, () => {handlerCallback()});
    }, [markers]);

    useEffect(() => {
        map.once(('style.load'), function () {
            addSymbolSource(map, markers, options);
        })
    }, [style]);
    
    return null
}
