import axios from "axios";
import { Button } from "../Button";
import { SelectContainer } from "../select-container";
import { toast } from 'react-toastify';
import { IconButton } from "@mui/material";
import { ArrowBack, Search } from "@mui/icons-material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setClienteToAdd } from "../../features/clienteToAdd/clienteToAddSlice.js";
import { handleInput } from "../../services";
import { geoInputs } from "../../const/geoInputs";
import { toastOptions } from '../../const/toastOptions.js';
import { apiPhp } from "../../const/apiPhp.js";

const clienteInputs = [
    { 
        inputType: "INPUT",
        title: "Cedula",
        name: "identidad",
        id: "input-identidad",
        type: "text",
        placeholder: "Agregar cedula",
    },
];

export const ClienteDetails = ({handleBack, map, onClose}) => {
    const [values, setValues] = useState({
        identidad: '',
        longitude: 0,
        latitude: 0,
    });
    const [data, setData] = useState(null);
    const [index, setIndex] = useState(0);
    const dispatch = useDispatch();

    const onChangeValues = (e) => {
        const { 
            target: {  
                name, 
                value 
            } 
        } = e;
        setValues(prevState => ({...prevState, [name]: value}))
    }
    const handleSearch = async () => {
        if (!values.identidad) {
            return;
        }
        try {
            const response = await axios.get(`${apiPhp}ci_values/?cedula=${values.identidad.replace(/[^\d]/gi, '')}`);
            
            const {
                data: {
                    status,
                    data
                }
            } = response;
            
            if (status) {
                setData(data);
                toast.info(`La cedula pertenece a ${data[0].company}.`, toastOptions);
            } else {
                setData(null);
                toast.error(`La cedula no pertenece a un cliente Fibex.`, toastOptions);
            }
        } catch (error) {
            toast.error(`Fallo en el servidor.`, toastOptions);
        }
    }

    const onChangeSelect = (e) => {
        const { 
            target: {  
                value 
            } 
        } = e;
        const ind = data.findIndex(item => item.abonadoid === value);
        setIndex(ind);
    }

    const handleUpdateCliente = (e) => {
        e.stopPropagation();
        const currentCliente = {
            ...data[index], 
            longitude: values.longitude,
            latitude: values.latitude,
        } 
        dispatch(setClienteToAdd(currentCliente));
        onClose();
    }

    return (
        <div className='attributes-container'>
            <div className='modal-content-section attributes-header'>
                <div className='back-menu-btn'>
                    <IconButton onClick={handleBack}>
                        <ArrowBack/>
                    </IconButton>
                </div>
                <div className='attributes-header-title'>
                    <h1>
                        Cliente
                    </h1>
                </div>
            </div>
            <div className='cedula-input-container'>
                {clienteInputs.map((input) => (
                    handleInput(input, values, onChangeValues)
                ))}
                <div className="search-btn">
                    <IconButton onClick={handleSearch}>
                        <Search/>
                    </IconButton>
                </div>
            </div>
            {data?.length > 1 ? 
                <SelectContainer
                    title="Abonados"
                    onChange={onChangeSelect}
                    options={data.map((item) => ({name: item.abonadoid, value: item.abonadoid}))}
                    id="select-abonado"
                    labelId="select-abonado"
                    name="abonados"
                    value={data[index].abonadoid}
                />
                
            : null}
           {data ? 
                (<>
                    <div className="name-cliente-container">
                        <h5>
                            Cliente:
                        </h5>
                        <p>
                            {data[index].company}
                        </p>
                    </div>
                    <div className="name-cliente-container">
                        <h5>
                            Abonado:
                        </h5>
                        <p>
                            {data[index].abonadoid}
                        </p>
                    </div>
                    <div className="name-cliente-container">
                        <h5>
                            Estado:
                        </h5>
                        <p>
                            {data[index].state}
                        </p>
                    </div>
                    <div className="name-cliente-container">
                        <h5>
                            Ciudad:
                        </h5>
                        <p>
                            {data[index].city}
                        </p>
                    </div>
                    <div className="name-cliente-container">
                        <h5>
                            Dirección:
                        </h5>
                        <p>
                            {data[index].address}
                        </p>
                    </div>
                    <div className="geo-inputs-container">
                        {geoInputs.map((input) => (
                            handleInput(input, values, onChangeValues)
                        ))}
                    </div>
                    
                    <div className='modal-content-section'>
                        <Button onClick={handleUpdateCliente}>
                            Seleccionar posición en el mapa
                        </Button>
                    </div>
                </>)
           : null}
        </div>
    )
}


        /*const { lng, lat } = map.getCenter();    
        const geojson = {
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [lng, lat]
                    }
                }
            ]
        };

        map.addSource('select-element', {
            type: 'geojson',
            data: geojson,
        })
            
        // styles para el archivo kml
        // agrega styles al los poligonos
        map.addLayer({
            'id': 'select-element',
            'source': 'select-element',
            'type': 'symbol',
            'layout': {
                'icon-image': "cliente-mapa",
                'icon-size': 0.04,
                'icon-allow-overlap': true, 
            },
        });
        const canvas = map.getCanvasContainer();
        canvas.style.cursor = 'crosshair';

        const onMove = (e) => {
            const coords = e.lngLat;
    
            // Update the Point feature in `geojson` coordinates
            // and call setData to the source layer `point` on it.
            geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
            map.getSource('select-element').setData(geojson);
        }

        const onStop = () => {
            const currentCliente = {
                ...data[index], 
                longitude: geojson.features[0].geometry.coordinates[0],
                latitude: geojson.features[0].geometry.coordinates[1],
            }            
            map.off('mousemove', onMove);
            map.removeLayer('select-element');
            map.removeSource('select-element');
            canvas.style.cursor = '';
            dispatch(setClienteToAdd(currentCliente));
        }

        map.on('mousemove', onMove);
        map.once('click', onStop);*/