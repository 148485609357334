import axios from 'axios';
import { ModalBase } from '../modal-base/index.js';
import { Button } from '../Button/index.js';
import { useState } from 'react';
import { 
  useDispatch, 
  useSelector 
} from 'react-redux';
import { toast } from 'react-toastify';
import { setProspecto } from '../../features/prospectosSlice/prospectosSlice.js';
import {
  setProspectoToAdd,
  selectProspectoToAdd
} from '../../features/prospectoToAdd/prospectoToAddSlice.js'
import * as images from '../../const/images.js';
import { toastOptions } from '../../const/toastOptions.js';
import { api } from '../../const/apiUpload.js';
import './index.css';

export const SaveProspecto = () => {
    const dispatch = useDispatch();
    const prospecto = useSelector(selectProspectoToAdd);
    const [processing, setProcessing] = useState(false);
    if (!prospecto) {
      return null;
    };
  
    const onClose = () => {
      setProcessing(false);
      dispatch(setProspectoToAdd(null));
    };
  
    const handleSubmit = async () => {
         console.log(prospecto)
      if (processing) {
        return;
      }
     try {
        setProcessing(true);
        const response = await axios.post(`${api}/create-prospectos`, {...prospecto,  "idProspectos": "" });
        const { data } = response;
        console.log(data)
       
        dispatch(setProspecto(data));
        toast.success(`El prospecto ${data.nombreProspecto}, ha sido agregado al mapa.`, toastOptions);
      } catch (error) {
        console.log(error.response);
        toast.error(`No se ha podido agregar el prospecto ${prospecto.nombreProspecto} al mapa.`, toastOptions);
      } 
      onClose()
    };
  
    return (
      <ModalBase
        id='Save-element-modal'
        title='Guardar prospecto'
        onClose={onClose}
      >
        <div className='modal-content-section'>
          <div className='element-name-container'>
            <p>
              {prospecto.nombreProspecto}
            </p>
          </div>
        </div>
        <div className='modal-content-section'>
          <div  className='element-image'>
            <img src={images[prospecto.tipoDeSolicitud.toLowerCase()]} alt="prospecto"/>
          </div>  
        </div>
        <div className='modal-content-section'>
            <div className='element-properties-container' >
              <p className='element-propertie'>Nombre: {prospecto.nombreProspecto}</p>
            </div>
            <div className='element-properties-container' >
              <p className='element-propertie'>Cedula: {prospecto.ciProspecto}</p>
            </div>
            <div className='element-properties-container' >
              <p className='element-propertie'>Telefono: {prospecto.telefono}</p>
            </div>
            <div className='element-properties-container' >
              <p className='element-propertie'>Ciudad: {prospecto.ciudad}</p>
            </div>
            <div className='element-properties-container' >
              <p className='element-propertie'>Zona: {prospecto.zona}</p>
            </div>
            <div className='element-properties-container' >
              <p className='element-propertie'>Dirección: {prospecto.direccion}</p>
            </div>
        </div>
   
        <div className='modal-content-section'>
          <Button onClick={handleSubmit} processing={processing} disabled={processing}>
            Guardar prospecto
          </Button>
        </div>
      </ModalBase> 
    )
}