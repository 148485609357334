import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

const prospectoToAddSlice = createSlice({
  name: "prospectoToAdd",
  initialState,
  reducers: {
    setProspectoToAdd: (state, action) => {
        state.data = action.payload;
    },
  },
});

export const { setProspectoToAdd } = prospectoToAddSlice.actions;

export const selectProspectoToAdd = (state) => state.prospectoToAdd.data;

export default  prospectoToAddSlice.reducer;