import { ModalBase } from '../modal-base/index.js';
import { SelectElement } from '../select-element/index.js';
import { AttributesContainer } from '../attributes-container/index.js';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectElements } from '../../features/elementsSlice/elementsSlice.js';

export const AddElement = ({map}) => {
    const elements = useSelector(selectElements);
    const [selected, setSelected] = useState(null);
    
    const handleSelect = (element) => {
        setSelected(element);
    }

    const onClose = () => {
        const target = document.getElementById('add-element-modal');
        target.classList.toggle("closed");
        setSelected(null);
    }
    
    return (
        <ModalBase
            id='add-element-modal'
            title='Seleccionar elemento'
            onClose={onClose}
            extend='closed'
        >   
            {selected ?
                <AttributesContainer 
                    map={map} 
                    selected={selected} 
                    handleSelect={handleSelect}
                    onClose={onClose}
                />
                : <SelectElement 
                    elements={elements} 
                    handleSelect={handleSelect}
                />
            }    
        </ModalBase>
    )
}